import { useCallback } from 'react';
import { EditSampleDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useUpdateSample = (sampleId: EditSampleDTO['sampleId']) => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'PUT:/api/Sample/UpdateSample',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: Partial<EditSampleDTO>) => {
      dispatch({ data, params: { sampleId } });
    },
    [dispatch, sampleId]
  );

  return { dispatch: dispatchWrapper, result };
};
