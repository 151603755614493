import { useEffect } from 'react';
import { ApiResult, SampleTypeDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

type SampleTypesList = Array<SampleTypeDTO>;

export const useGetSampleTypes = (): ApiResult<
  SampleTypesList,
  'sampleTypes'
> & {
  refetch: () => void;
} => {
  const { dispatch, result } = useApiWrapper<SampleTypesList, 'sampleTypes'>(
    'GET:/api/SampleType/GetActiveSampleTypes',
    'sampleTypes'
  );

  useEffect(() => {
    dispatch();
  }, [dispatch]);

  return { ...result, refetch: dispatch };
};
