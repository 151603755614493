import { useEffect } from 'react';
import { SampleDTO, SampleSpecDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSampleTypeSpec = (
  sampleTypeId: SampleDTO['sampleTypeId'],
  version?: SampleDTO['sampleTypeSpecVersion']
) => {
  const { dispatch, result } = useApiWrapper<SampleSpecDTO, 'sampleTypeSpec'>(
    'GET:/api/SampleType/GetSampleTypeSpec',
    'sampleTypeSpec'
  );

  useEffect(() => {
    sampleTypeId && dispatch({ params: { sampleTypeId, version } });
  }, [dispatch, sampleTypeId, version]);

  return result;
};
